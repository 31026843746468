import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ Component, props, ...rest }) => {
  const tkn = localStorage.getItem("token") || null;

  const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
      return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (exp) => {
    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  };

  const auth = !isExpired(getExpirationDate(tkn));

  return tkn && auth ? <Route {...rest} /> : <Redirect to="/" />;
};

export default PrivateRoute;
