import "./App.css";
import Formulaire from "./pages/Formulaire";
import { ThemeProvider } from '@material-ui/core/styles';
import Contacts from "./pages/Contacts";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import FicheContact from "./pages/FicheContact";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Switch,
    Route,
    HashRouter
} from "react-router-dom";
import PrivateRoute from "./composants/PrivateRoute";
import coachimmo from "./theme/coachimmo";

function App() {
  return (
      <ThemeProvider theme={coachimmo}>
        <HashRouter>
            <Switch>
                <PrivateRoute path="/formulaire">
                    <Formulaire />
                </PrivateRoute>
                <PrivateRoute path="/mes-contacts">
                    <Contacts />
                </PrivateRoute>
                <PrivateRoute path="/contact/:id">
                    <FicheContact />
                </PrivateRoute>
                <Route path="/mot-de-passe-oublie">
                    <ForgottenPassword />
                </Route>
                <Route path="/reset-password/:token">
                    <ResetPassword />
                </Route>
                <Route path="/logout">
                    <Logout />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </HashRouter>
      </ThemeProvider>
  );
}

export default App;
