import React from "react";
import {
  ACHETER_DANS_LANCIEN_LOCATIF,
  ACHETER_DANS_LANCIEN_RESIDENTIEL,
  ACHETER_DANS_LE_NEUF,
  ASSURER_VOS_LOYERS,
  ESTIMER_VENDRE,
  ETAT_DES_LIEUX,
  FAIRE_DIAGNOSTIC,
  FAIRE_GERER,
  LOUER,
  TROUVER,
} from "../constants/constants";
import Logo from "../styles/pages/formulaire/Logo";

const Motif = ({ handleChecked, motifs, error, checkedValues }) => {
  return (
    <div className="motif">
      <div>
        <p>
          Motif de la demande<span>*</span>
        </p>
        <p className="choix">Plusieurs choix possibles.</p>
      </div>
      <div className="checkBox">
        {motifs.map((hero, index) => (
          <label key={index}>
            <input
              disabled={
                (checkedValues.includes(ESTIMER_VENDRE) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(ACHETER_DANS_LANCIEN_LOCATIF) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(ACHETER_DANS_LANCIEN_RESIDENTIEL) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(FAIRE_GERER) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(ASSURER_VOS_LOYERS) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(ETAT_DES_LIEUX) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(FAIRE_DIAGNOSTIC) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(LOUER) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(TROUVER) &&
                  hero === ACHETER_DANS_LE_NEUF) ||
                (checkedValues.includes(ACHETER_DANS_LE_NEUF) &&
                  hero !== ACHETER_DANS_LE_NEUF)
                  ? true
                  : false
              }
              type="checkbox"
              data-id={index}
              name={hero}
              onClick={handleChecked}
            />
            {hero}
          </label>
        ))}

        <span>{error.motif}</span>
      </div>
      <div>
        {/* Affichage des partenaires concerné en fonction des cases cochées */}
        <p className="partenaire">Partenaire(s) concerné(s)</p>
        {checkedValues.includes(ESTIMER_VENDRE) ||
        checkedValues.includes(ACHETER_DANS_LANCIEN_LOCATIF) ||
        checkedValues.includes(ACHETER_DANS_LANCIEN_RESIDENTIEL) ||
        // checkedValues.includes(ACHETER_DANS_LE_NEUF) ||
        checkedValues.includes(FAIRE_GERER) ||
        checkedValues.includes(LOUER) ||
        checkedValues.includes(TROUVER) ? (
          <Logo
            className="slide-in-left"
            src="images/citya.svg"
            alt="Logo Citya Immobilier"
          />
        ) : null}
        {checkedValues.includes(FAIRE_GERER) ? (
          <Logo
            className="slide-in-left"
            src="images/citya_dev.svg"
            alt="Logo Citya Développement"
          />
        ) : null}
        {checkedValues.includes(ACHETER_DANS_LE_NEUF) ? (
          <>
            {" "}
            <Logo
              className="slide-in-left"
              src="images/logo_VDFT.png"
              alt="Logo Citya"
            />{" "}
            <br />{" "}
            <p style={{ fontSize: "14px" }}>
              {" "}
              Procédure VDFT inchangée => Pour la prise en charge du lead,
              remplir <br /> et faire signer la fiche de mise en relation
              disponible ici :{" "}
              <a
                target="_blank"
                href="http://intranet3.bpvf.ibp/ClicDoc"
                rel="noreferrer"
              >
                http://intranet3.bpvf.ibp/ClicDoc{" "}
              </a>{" "}
            </p>{" "}
          </>
        ) : null}
        {checkedValues.includes(ASSURER_VOS_LOYERS) ? (
          <Logo
            className="slide-in-left"
            src="images/belvia.svg"
            alt="Logo Belvia Garanties"
          />
        ) : null}
        {checkedValues.includes(ETAT_DES_LIEUX) ||
        checkedValues.includes(FAIRE_DIAGNOSTIC) ? (
          <Logo
            className="slide-in-left"
            src="images/snexi.svg"
            alt="Logo Snexi"
          />
        ) : null}
      </div>
    </div>
  );
};

export default Motif;
