import { ErrorMessage } from "@hookform/error-message";
import React from "react";

const Input = ({
  label,
  handleChange,
  type,
  placeholder,
  name,
  form,
  messageText,
}) => {
  return (
    <>
      <label>
        {label}
        <span>*</span>
      </label>
      <div className="input">
        <input
          onChange={handleChange}
          className="inside"
          type={type}
          placeholder={placeholder}
          name={name}
          ref={form.register({ required: true })}
        />
        <ErrorMessage
          errors={form.errors}
          name={name}
          message={messageText}
          render={({ message }) => (
            <span>
              <br />
              {message}
            </span>
          )}
        />
      </div>
    </>
  );
};

export default Input;
