export const ESTIMER_VENDRE = "Estimer/vendre";
export const ACHETER_DANS_LANCIEN_LOCATIF = "Acheter dans l’ancien / Locatif";
export const ACHETER_DANS_LANCIEN_RESIDENTIEL =
  "Acheter dans l’ancien / Résidentiel";
export const ACHETER_DANS_LE_NEUF = "Acheter dans le neuf (en VEFA)";
export const FAIRE_GERER = "Faire gérer";
export const ASSURER_VOS_LOYERS = "Garantir vos loyers";
export const ETAT_DES_LIEUX = "Faire un état des lieux";
export const FAIRE_DIAGNOSTIC = "Faire un diagnostic";
export const LOUER = "Louer";
export const TROUVER = "Trouver un locataire";
