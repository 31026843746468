import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import {toast} from "react-toastify";
import {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {FORGOTTEN_PASSWORD} from "../constants/RoutesApi";

const axios = require("axios");

const ForgottenPassword = () => {

    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: `url(https://media.sas-arche.com/apps_back_HD/${Math.floor(Math.random() * (20 - 1 + 1)) + 1}.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));
    const classes = useStyles();

    const onSubmit = (data) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_URL_API}${FORGOTTEN_PASSWORD}`, data)
            .then(function (data) {
                const res = data.data;
                if(!res.success){
                    toast.error(res.message)
                } else {
                    toast.success(res.message)
                }
            })
            .catch(function (error) {
                toast.error('Une erreur est survenue');
            })
            .finally(() => {
                    setLoading(false);
                }
            )
        ;
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={8} className={classes.image} />
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Coach Immo - Mot de passe oublié
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Adresse email"
                            name="mail"
                            autoComplete="mail"
                            autoFocus
                            inputProps={{ ref: register }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            className={classes.submit}
                        >
                            Réinitialiser
                        </Button>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}

export default ForgottenPassword;