import { createMuiTheme } from '@material-ui/core/styles';
import { frFR } from '@material-ui/data-grid';

const coachimmo = createMuiTheme({
    palette: {
        primary: {
            main: '#051b71',
        },
    },
}, frFR);

export default coachimmo;