import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const ContactCard = (props) => {
    const contact = props.contact;
    const intlFormat = new Intl.DateTimeFormat('fr-FR', {timeStyle: 'short'});

    return (
        <Grid item lg={5}>
            <Paper>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Contact
                        </Typography>
                        <Typography variant="body2" component="p">
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <PersonOutlineIcon/>
                                </Grid>
                                <Grid item>
                                    &nbsp;{contact.prenomContact} {contact.nomContact}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <MailOutlineIcon/>
                                </Grid>
                                <Grid item>
                                    &nbsp;{contact.emailContact}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <PhoneIcon/>
                                </Grid>
                                <Grid item>
                                    &nbsp;{contact.telephoneContact}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <DateRangeIcon/>
                                </Grid>
                                <Grid item>
                                    &nbsp;{contact.preferenceContactJour ? contact.preferenceContactJour.join(', ') : ''} entre {
                                    contact.preferenceContactHeureDebut ? intlFormat.format(new Date(contact.preferenceContactHeureDebut)) : ''
                                } et {
                                    contact.preferenceContactHeureFin ? intlFormat.format(new Date(contact.preferenceContactHeureFin)) : ''
                                }
                                </Grid>
                            </Grid>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <LocationOnIcon/>
                                </Grid>
                                <Grid item>
                                    &nbsp;{contact.villeContact}
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    );
}

export default ContactCard;