import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";

const MotifsTable = (props) => {
    const intlFormat = new Intl.DateTimeFormat('fr-FR', {dateTime: 'short'});
    const displayDate = (motif) => {
        const filtered = motif.historiques.filter(historique => motif.etat === historique.etat);
        if (filtered.length > 0) {
            const hstiro = filtered[filtered.length - 1];
            return intlFormat.format(new Date(hstiro.date));
        }
        return '-';
    }
    return (
        <Grid item lg={7}>
            <Paper>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Motifs de prise de contact
                        </Typography>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Libellé
                                        </TableCell>
                                        <TableCell>
                                            Reçu le
                                        </TableCell>
                                        <TableCell>
                                            Etat
                                        </TableCell>
                                        <TableCell>
                                            Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.motifs ? props.motifs.map(motif => {
                                            return (
                                                <TableRow key={motif['@id']}>
                                                    <TableCell>
                                                        {motif.libelle}
                                                    </TableCell>
                                                    <TableCell>
                                                        {intlFormat.format(new Date(motif.dateReception))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {motif.etat}
                                                    </TableCell>
                                                    <TableCell>
                                                        {displayDate(motif)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }) : <>Une erreur est survenue</>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    )
};

export default MotifsTable;