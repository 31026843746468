import styled from "styled-components";

const TitleForm = styled.h1`
  text-align: center;
  text-transform: uppercase;
  color: #051b71;
  font-size: 2.2em;
  span {
    text-transform: none;
  }
`;

export default TitleForm;
