import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import {toast} from "react-toastify";
import history from '../helpers/history'
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {LOGIN} from "../constants/RoutesApi";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const axios = require("axios");

const Login = () => {

    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: `url(https://media.sas-arche.com/apps_back_HD/${Math.floor(Math.random() * (20 - 1 + 1)) + 1}.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));
    const classes = useStyles();

    const onSubmit = (data) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_URL_API}${LOGIN}`, data)
            .then(function (data) {
                if (data.status === 200) {
                    localStorage.setItem('token', data['data']['token']);
                    history.push('/formulaire');
                }
            })
            .catch(function (error) {
                toast.error('Connexion impossible');
            })
            .finally(() => {
                    setLoading(false);
            }
            )
        ;
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={8} className={classes.image} />
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                       Coach Immo
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Adresse email"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            inputProps={{ ref: register }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            autoComplete="current-password"
                            inputProps={{ ref: register }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            className={classes.submit}
                        >
                            Se connecter
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link component={RouterLink} to="/mot-de-passe-oublie" variant="body2">
                                    Mot de passe oublié ?
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                    <ul style={{textAlign: 'center', listStyleType: 'none', margin: 0, padding: 0}}>
                        <li style={{marginBottom: '1rem'}}>
                            <img src="/images/logo_banque.png" alt="Logo Banque Populaire" style={{maxWidth: '275px'}} />
                        </li>
                        <li style={{marginBottom: '1rem'}}>
                            <img src="/images/citya.svg" alt="Logo Citya Immobilier" style={{width: '90px', marginRight: '1rem'}} />
                            <img src="/images/citya_dev.svg" alt="Logo Citya Développement" style={{width: '90px'}} />
                        </li>
                        <li>
                            <img src="/images/belvia.svg" alt="Logo Belvia Garanties" style={{width: '90px', marginRight: '1rem'}} />
                            <img src="/images/snexi.svg" alt="Logo Snexi" style={{width: '90px'}} />
                        </li>
                    </ul>
                </div>
            </Grid>
        </Grid>
    )
}

export default Login;