import styled from "styled-components";

const Footer = styled.footer`
  height: 100px;
  background-color: #051b71;
  text-align: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
  }
`;

export default Footer;
