import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory , Link as RouterLink } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        maxHeight: 50,
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    }
}));

const NavBar = () => {
    const classes = useStyles();
    const history = useHistory();
    const disco = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        history.push('/');
    }
    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="inherit">
                <Toolbar>
                    <Typography variant="subtitle2" color="inherit">
                        <img src="/images/logo_banque.png" className={classes.logo} alt="Logo Banque Populaire" />
                    </Typography>
                    <section className={classes.rightToolbar}>
                        <Button color="inherit" component={RouterLink} to="/formulaire">Formulaire</Button>
                        <Button color="inherit" component={RouterLink} to="/mes-contacts">Mes contacts</Button>
                        <Button color="inherit" onClick={disco}>Se déconnecter</Button>
                    </section>
                </Toolbar>
            </AppBar>
        </div>
        /*<AppBar position="static">
            <Toolbar>
                <Typography variant="h6" noWrap>
                    Coach Immo
                </Typography>
                <Button color="inherit" href="/#/formulaire">Formulaire</Button>
                <Button color="inherit" href="/#/mes-contacts">Mes contacts</Button>
                <Button  color="inherit">Login</Button>
            </Toolbar>
        </AppBar>*/
    )
};

export default NavBar;