import styled from "styled-components";

const Form = styled.form`
  max-width: 1280px;
  margin: 0 auto;
  background-color: white;
  color: #051b71;
  padding: 2rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  .container_btn {
    justify-content: flex-end;
    display: flex;
  }
  .fullEmail {
    width: 47rem;
  }
  .titleClient {
    width: 15rem;
    height: auto;
  }
  .titleConseiller {
    width: 15rem;
    height: auto;
  }
  .btn {
    background-color: #051b71;
    border: none;
    color: white;
    height: 45px;
    width: 112px;
    text-align: center;
    text-decoration: none;
    margin-top: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .accepter {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    p {
      margin-bottom: 10px;
    }
  }
  label {
    font-weight: bold;
    margin-top: 5px;
  }
  span {
    color: red;
  }
  p {
    font-weight: bold;
    margin: 0;
  }
  .choix {
    font-weight: normal;
    font-size: 0.8em;
  }
  .motif {
    font-size: 1.2em;
    width: 90%;
    margin: 0 auto 10px;
    justify-content: space-between;
    display: flex;
  }
  .checkBox {
    display: flex;
    flex-direction: column;
    div {
      input {
        margin-right: 5px;
      }
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .partenaire {
    margin-bottom: 10px !important;
  }
  .conseiller {
    background-color: white !important;
  }
  .contact {
    input[type="checkbox"] {
      width: auto;
      margin-right: 10px;
    }
  }
  .client,
  .contact {
    display: flex;
    padding: 1rem;
    background-color: #e6f0f7;
    width: 90%;
    margin: 0 auto 10px;
    .bloc {
      float: left;
      clear: none;
    }
    input {
      width: 23rem;
      padding: 12px 20px;
      margin-top: 5px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    input.fullsize {
      width: 100%;
    }
    .mr {
      margin-right: 15px;
    }
    .full {
      width: 100%;
    }
  }
  .client,
  .conseiller {
    display: flex;
    padding: 1rem;
    background-color: #e6f0f7;
    width: 90%;
    margin: 0 auto 10px;
    .bloc {
      float: left;
      clear: none;
    }
    input {
      width: 23rem;
      padding: 12px 20px;
      margin-top: 5px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    input.fullsize {
      width: 100%;
    }
    .mr {
      margin-right: 15px;
    }
    .full {
      width: 100%;
    }
  }
  .blocDate {
    display: flex;
    align-items: baseline;
    p {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .center {
    margin: 0 auto;
  }
  .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-200px);
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-200px);
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1620px) {
    .client .bloc {
      float: none;
    }
    .mr {
      margin-right: 5px !important;
    }
    input.inside {
      width: 47rem;
    }
  }
  @media (max-width: 1344px) {
    input.inside,
    .fullEmail {
      width: 35rem;
    }
    .blocDate {
      flex-direction: column;
    }
  }
  @media (max-width: 1024px) {
    input.inside,
    .fullEmail {
      width: 25rem;
    }
  }
  @media (max-width: 767px) {
    input.inside,
    .fullEmail {
      width: 15rem;
    }
    .blocDate {
      input {
        width: 15rem;
      }
    }
  }
  @media (max-width: 767px) {
    input.inside,
    .fullEmail {
      width: 10rem;
    }
    .blocDate {
      input {
        width: 10rem;
      }
    }
  }
`;

export default Form;
