import React, {useEffect, useState} from "react";
import axios from "../helpers/axios";
import {useParams} from 'react-router-dom'
import ContactCard from "../composants/ContactCard";
import BannerImg from "../styles/pages/formulaire/BannerImg";
import TitleForm from "../styles/pages/formulaire/TitleForm";
import Grid from '@material-ui/core/Grid';
import MotifsTables from "../composants/MotifsTable";
import CRMLayout from "../styles/pages/CRMLayout"
import {CONTACTS} from "../constants/RoutesApi";
import NavBar from "../composants/NavBar";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {toast} from "react-toastify";

const FicheContact = () => {
    const [loading, setLoading] = useState(false)
    const [contact, setContact] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL_API}${CONTACTS}/${id}`);
                setContact(response.data);
            } catch (e) {
                setContact(null);
                toast.error('Une erreur est survenue, contact introuvable');
            } finally {
                setLoading(false);
            }
        })();
    }, [])

    return (
        <>
            <NavBar/>
            <BannerImg className="image" src="images/banner.jpg" alt="" />
            <CRMLayout>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/#/mes-contacts">
                        Mes contacts
                    </Link>
                    <Typography color="textPrimary">Fiche de contact</Typography>
                </Breadcrumbs>
                <TitleForm>
                    <span>Coach Immo</span> <br /> Fiche de Contact
                </TitleForm>
                        <div>
                            {
                                loading ? <h1>Chargement en cours</h1> : (
                                    contact ? (
                                        <Grid container spacing={3}>
                                            <ContactCard contact={contact}/>
                                            <MotifsTables motifs={contact.motifs}/>
                                        </Grid>
                                    ) : null
                                )
                            }
                        </div>
            </CRMLayout>
        </>
    )
};

export default  FicheContact;