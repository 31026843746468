import React, {useEffect, useState} from "react";
import axios from "../helpers/axios";
import { DataGrid } from '@material-ui/data-grid';
import BannerImg from "../styles/pages/formulaire/BannerImg";
import TitleForm from "../styles/pages/formulaire/TitleForm";
import CRMLayout from "../styles/pages/CRMLayout";
import {Paper} from "@material-ui/core";
import NavBar from "../composants/NavBar";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";

const Contacts = () => {
    const history = useHistory();
    const columns = [
        {
            field: 'user.nom',
            headerName: 'Collaborateur',
            valueGetter: (params) => {
                if (typeof params.row.user !== 'undefined') {
                    return `${params.row.user.prenom} ${params.row.user.nom}`;
                } else {
                    return '-';
                }
            },
            hide: false,
            sortable: false,
            width: 250,
        },
        {
            field: 'user.banques.groupe.departement.libelle',
            headerName: 'Département',
            valueGetter: (params) => {
                if (typeof params.row.user !== 'undefined' && params.row.user.banques.length === 1) {
                    return params.row.user.banques[0].groupe.departement.libelle;
                } else {
                    return '-';
                }
            },
            hide: false,
            sortable: false,
            width: 250,
        },
        {
            field: 'user.banques.groupe.libelle',
            headerName: 'Groupement',
            valueGetter: (params) => {
                if (typeof params.row.user !== 'undefined' && params.row.user.banques.length === 1) {
                    return params.row.user.banques[0].groupe.libelle;
                } else {
                    return '-';
                }
            },
            hide: false,
            sortable: false,
            width: 250,
        },
        {
            field: 'user.banques.libelle',
            headerName: 'Agence',
            valueGetter: (params) => {
                if (typeof params.row.user !== 'undefined' && params.row.user.banques.length === 1) {
                    return params.row.user.banques[0].libelle;
                } else {
                    return '-';
                }
            },
            hide: false,
            sortable: false,
            width: 250,
        },
        {
            field: 'nomContact',
            headerName: 'Contact',
            valueGetter: (params) => `${params.row.prenomContact} ${params.row.nomContact}`,
            hide: false,
            sortable: false,
            width: 250,
        },
    ];

    // page du tableau
    const [page, setPage] = useState(1);
    // nombre d'items par page
    const [pageSize, setPageSize] = useState(25);
    // résultats
    const [rows, setRows] = useState([]);
    // nombre d'items au total
    const [totalItems, setTotalItems] = useState(0);
    // loader du tableau
    const [loading, setLoading] = useState(false);
    // filtres appliqués à l'api
    const [filters, setFilters] = useState('');

    const handlePageChange = (params) => {
        setPage(params.page + 1);
    };
    const handlePageSizeChange = (params) => {
        setPageSize(params.pageSize);
    };
    const handleRowClick = (params) => {
        history.push(`/contact/${params.id.replace('/contacts/', '')}`);
    };
    const handleFilterModelChange = (params) => {
        const items = params.filterModel.items;
        let filtres = '';
        for (let i = 0; i < items.length; i++) {
            if (typeof items[i].value !== 'undefined') {
                filtres += `&${items[i].columnField}=${items[i].value}`;
            }
        }
        setFilters(filtres);
    }

    useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);
            try {
                let urlParams = `?page=${page}&itemsPerPage=${pageSize}${filters}`;
                const response = await axios.get(
                    `${process.env.REACT_APP_URL_API}/contacts${urlParams}`,
                );
                setRows(response.data['hydra:member']);
                setTotalItems(response.data['hydra:totalItems']);
            } catch (e) {
                if (e.response.status === 403) {
                    history.push('/');
                    toast.error("Votre session a expiré. Veuillez vous reconnecter.");
                }
            }
            if (!active) {
                return;
            }
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    },[page, pageSize, filters]);

    return (
        <>
            <NavBar/>
            <BannerImg className="image" src="images/banner.jpg" alt="" />
            <CRMLayout>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Mes contacts</Typography>
                </Breadcrumbs>
                <TitleForm>
                    <span>Coach Immo</span> <br /> Mes Contacts
                </TitleForm>
                <Paper>
                    <DataGrid
                        density="compact"
                        autoHeight
                        getRowId={(r) => r['@id'] }
                        columns={columns}
                        filterMode="server"
                        onFilterModelChange={handleFilterModelChange}
                        pageSize={pageSize}
                        rowCount={totalItems}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pagination
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        rows={rows}
                        sortingMode="server"
                        onRowClick={handleRowClick}
                        loading={loading}
                    />
                </Paper>
            </CRMLayout>
        </>
    )
}

export default  Contacts;