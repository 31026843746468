import {ErrorMessage} from "@hookform/error-message";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Client from "../composants/Client";
import Motif from "../composants/Motif";
import {
    ACHETER_DANS_LANCIEN_LOCATIF,
    ACHETER_DANS_LANCIEN_RESIDENTIEL,
    ACHETER_DANS_LE_NEUF,
    ASSURER_VOS_LOYERS,
    ESTIMER_VENDRE,
    ETAT_DES_LIEUX,
    FAIRE_DIAGNOSTIC,
    FAIRE_GERER,
    LOUER,
    TROUVER,
} from "../constants/constants";
import Form from "../styles/pages/Form";
import BannerImg from "../styles/pages/formulaire/BannerImg";
import Footer from "../styles/pages/formulaire/Footer";
import Loader from "../styles/pages/formulaire/Loader";
import TitleForm from "../styles/pages/formulaire/TitleForm";
import NavBar from "../composants/NavBar";
import {CONTACTS} from "../constants/RoutesApi";
import {useHistory} from "react-router-dom";
import axios from "../helpers/axios";

const Formulaire = () => {
    const history = useHistory();
    const form = useForm();
    const {register, handleSubmit, errors} = form;
    const [checkedValues, setCheckedValues] = useState([]);
    const [checkedValuesJour, setCheckedValuesJour] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState({
        motif: "",
        jours: "",
        heures: "",
    });
    const [dataFormulaire, setDataFormulaire] = useState({
        motifs: [],
        nomContact: "",
        prenomContact: "",
        emailContact: "",
        telephoneContact: "",
        villeContact: "",
        preferenceContactJour: [],
        preferenceContactHeureDebut: "",
        preferenceContactHeureFin: "",
        consentement: false,
    });

    let motifs = [
        ESTIMER_VENDRE,
        ACHETER_DANS_LANCIEN_LOCATIF,
        ACHETER_DANS_LANCIEN_RESIDENTIEL,
        ACHETER_DANS_LE_NEUF,
        FAIRE_GERER,
        ASSURER_VOS_LOYERS,
        ETAT_DES_LIEUX,
        FAIRE_DIAGNOSTIC,
        LOUER,
        TROUVER,
    ];

    let jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

    // verifie si l'heure de debut et plus petite que l'heure de fin pour reset l'erreur
    useEffect(() => {
        if (
            parseInt(dataFormulaire.preferenceContactHeureDebut) <
            parseInt(dataFormulaire.preferenceContactHeureFin)
        ) {
            setError((prevState) => ({
                ...prevState,
                heures: "",
            }));
        }
    }, [dataFormulaire]);

    // gestion checkbox motifs, creation d'un tableau avec les partenaires coché et set dans les data du formulaire
    const handleChecked = (e) => {
        const hero = motifs[e.target.dataset.id];
        let newCheckedValues = checkedValues.filter((item) => item !== hero);
        if (e.target.checked) newCheckedValues.push(hero);
        setError((prevState) => ({
            ...prevState,
            motif: "",
        }));
        setCheckedValues((checkedValues) => (checkedValues = newCheckedValues));
        setDataFormulaire((prevState) => ({
            ...prevState,
            motifs: newCheckedValues.map((item) => ({libelle: item})),
        }));
    };

    // gestion checkbox jour, creation d'un tableau avec les jours coché et set dans les data du formulaire
    const handleCheckedJour = (e) => {
        const jour = jours[e.target.dataset.id];
        let newCheckedValuesJour = checkedValuesJour.filter(
            (item) => item !== jour
        );
        if (e.target.checked) newCheckedValuesJour.push(jour);
        setError((prevState) => ({
            ...prevState,
            jours: "",
        }));
        setCheckedValuesJour(
            (checkedValuesJour) => (checkedValuesJour = newCheckedValuesJour)
        );
        setDataFormulaire((prevState) => ({
            ...prevState,
            preferenceContactJour: newCheckedValuesJour,
        }));
    };

    // gestion changement dans les input
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setDataFormulaire({...dataFormulaire, [name]: value});
    };

    // gestion envoi du formulaire
    const onSubmit = (data, e) => {
        if (
            checkedValues.length >= 1 &&
            checkedValuesJour.length >= 1 &&
            parseInt(dataFormulaire.preferenceContactHeureDebut) <=
            parseInt(dataFormulaire.preferenceContactHeureFin)
        ) {
            setError((prevState) => ({
                ...prevState,
                heures: "",
            }));
            setSpinner(true);
            axios
                .post(process.env.REACT_APP_URL_API + CONTACTS, dataFormulaire)
                .then((response) => {
                    toast.success(
                        "La demande de contact a bien été prise en compte. Une réponse sera apportée sous 48h ouvrées."
                    );
                    e.target.reset();
                    setCheckedValues([]);
                    setCheckedValuesJour([]);
                })
                .catch((error) => {
                    if (error.response.data["hydra:title"]) {
                        toast.error(error.response.data["hydra:description"]);
                    } else {
                        if (error.response.status === 403) {
                            history.push("/");
                            toast.error("Votre session a expiré. Veuillez vous reconnecter.");
                        } else {
                            toast.error("Une erreur serveur est survenue !");
                            e.target.reset();
                        }
                    }
                })
                .finally(() => {
                    setSpinner(false);
                });
        } else if (checkedValues.length <= 0) {
            setError((prevState) => ({
                ...prevState,
                motif: "Un motif est obligatoire.",
            }));
        } else if (checkedValuesJour.length <= 0) {
            setError((prevState) => ({
                ...prevState,
                jours: "Un jour est obligatoire.",
            }));
        } else if (
            parseInt(dataFormulaire.preferenceContactHeureDebut) >
            parseInt(dataFormulaire.preferenceContactHeureFin)
        ) {
            setError((prevState) => ({
                ...prevState,
                heures: "L'heure de début ne peut être supérieure à l'heure de fin.",
            }));
        }
    };

    const handleCheckedConsentement = (e) => {
        if (e.target.checked) {
            setDataFormulaire((prevState) => ({
                ...prevState,
                consentement: true,
            }));
        } else {
            setDataFormulaire((prevState) => ({
                ...prevState,
                consentement: false,
            }));
        }
    };

    return (
        <>
            <NavBar/>
            <BannerImg className="image" src="images/banner.jpg" alt=""/>
            <TitleForm>
                <span>Coach Immo</span> <br/> Formulaire de mise en relation
            </TitleForm>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Motif
                    handleChecked={handleChecked}
                    motifs={motifs}
                    error={error}
                    checkedValues={checkedValues}
                />
                <Client form={form} handleChange={handleChange}/>
                {/*<div className="conseiller">
          <div className="titleClient">
            <p>Conseiller</p>
            <p className="choix">Les coordonnées du conseiller.</p>
          </div>
          <div className="bloc center">
            <label>
              Email<span>*</span>
            </label>
            <div className="fullEmail">
              <input
                onChange={handleChange}
                className="fullsize"
                type="email"
                placeholder="prenom.nom@bpvf.fr"
                name="emailConseiller"
                ref={register({
                  required: true,
                  pattern: /[a-z0-9._]+@[a-z0-9-]+\.[a-z]{2,3}/i,
                })}
              />
              <ErrorMessage
                errors={errors}
                name="emailConseiller"
                message="Email du conseiller requis ou format incorrect."
                render={({ message }) => <span>{message}</span>}
              />
            </div>
          </div>
        </div>*/}
                <div className="contact">
                    <div className="titleClient">
                        <p>
                            Être contacté<span>*</span>
                        </p>
                        <p className="choix">
                            Sélectionnez des disponibilités entre 09h00 et 19h00.
                        </p>
                    </div>
                    <div className="content center">
                        <div className="date">
                            {jours.map((jour, index) => (
                                // <Checkboxmotifjours key={ Math.random().toString(36).substr(2, 9) } jour={jour} index={index}/>
                                <label key={index}>
                                    {jour}
                                    <input
                                        type="checkbox"
                                        data-id={index}
                                        placeholder={jour}
                                        name={jour}
                                        onClick={handleCheckedJour}
                                        ref={register({required: false})}
                                    />
                                </label>
                            ))}
                            <span>{error.jours}</span>
                        </div>
                        <div className="blocDate">
                            <div className="input">
                                <input
                                    onChange={handleChange}
                                    type="time"
                                    min="09:00"
                                    max="19:00"
                                    name="preferenceContactHeureDebut"
                                    ref={register({required: false})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="preferenceContactHeureFin"
                                    message="Heure de début requis."
                                    render={({message}) => (
                                        <span>
                      <br/>
                                            {message}
                    </span>
                                    )}
                                />
                            </div>
                            <p>à</p>
                            <div className="input">
                                <input
                                    onChange={handleChange}
                                    type="time"
                                    min="09:00"
                                    max="19:00"
                                    name="preferenceContactHeureFin"
                                    ref={register({required: true})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="preferenceContactHeureFin"
                                    message="Heure de début fin."
                                    render={({message}) => (
                                        <span>
                      <br/>
                                            {message}
                    </span>
                                    )}
                                />
                            </div>
                        </div>
                        <span>{error.heures}</span>
                    </div>
                </div>
                <div className="accepter">
                    <label htmlFor="consentement">
                        <input
                            onClick={handleCheckedConsentement}
                            type="checkbox"
                            placeholder="accepter"
                            name="consentement"
                            id="consentement"
                            ref={register({required: true})}
                        />
                        J’accepte et consens en présence de mon conseiller clientèle Banque Populaire Val de France, la
                        transmission et le traitement de mes données personnelles par Arche et ses filiales, dont la liste est disponible à l’adresse suivante : <a href='https://www.sas-arche.com/partenaires' target="_blank" rel="noopener noreferrer">www.sas-arche.com/partenaires</a> <span>*</span>
                    </label>
                    <ErrorMessage
                        errors={errors}
                        name="consentement"
                        message="Consentement requis."
                        render={({message}) => <span>{message}</span>}
                    />
                    <br/>
                    <div>
                        <p>
                            Les informations recueillies vous concernant font l’objet d’un
                            traitement destiné à :&nbsp;
                            {checkedValues.includes(ESTIMER_VENDRE) ||
                            checkedValues.includes(ACHETER_DANS_LANCIEN_LOCATIF) ||
                            checkedValues.includes(ACHETER_DANS_LANCIEN_RESIDENTIEL) ||
                            checkedValues.includes(FAIRE_GERER) ||
                            checkedValues.includes(LOUER) ||
                            checkedValues.includes(TROUVER)
                                ? "Citya Immobilier "
                                : null}
                            {checkedValues.includes(FAIRE_GERER)
                                ? "Citya Développement "
                                : null}
                            {checkedValues.includes(ACHETER_DANS_LE_NEUF)
                                ? "Val de France Transaction "
                                : null}
                            {checkedValues.includes(ASSURER_VOS_LOYERS)
                                ? "Belvia Garanties "
                                : null}
                            {checkedValues.includes(ETAT_DES_LIEUX) ||
                            checkedValues.includes(FAIRE_DIAGNOSTIC)
                                ? "Snexi "
                                : null}
                            . &nbsp;Pour la finalité suivante :&nbsp;
                            {checkedValues.join(", ")}
                            &nbsp;La durée de conservation des données est de 3 ans.
                        </p>
                        <p>
                            Vous bénéficiez d’un droit d’accès, de rectification, de
                            portabilité, d’effacement de celles-ci ou une limitation du
                            traitement. Vous pouvez vous opposer au traitement des données
                            vous concernant et disposez du droit de retirer votre consentement
                            à tout moment en vous adressant à :{" "}
                            <a href="mailto:rgpd@sas-arche.com">rgpd@sas-arche.com</a>. Vous avez la
                            possibilité d’introduire une réclamation auprès d’une autorité de
                            contrôle.
                        </p>
                    </div>
                    <br/>
                    <div>
                        <p>
                            <span>*</span>Champ obligatoire
                        </p>
                    </div>
                </div>

                <div className="container_btn">
                    {spinner ? (
                        <Loader>
                            <div className="btn lds-dual-ring"></div>
                        </Loader>
                    ) : (
                        <input className="btn" type="submit"/>
                    )}
                </div>
            </Form>
            <Footer>
                <a href="https://coachimmo.sas-arche.com">Mentions légales</a>
                <a href="https://coachimmo.sas-arche.com">Données personnelles</a>
            </Footer>
        </>
    );
};

export default Formulaire;
