import {useEffect} from "react";
import history from "../helpers/history";

const Logout = () => {
    useEffect(() => {
        // On vide le localstorage
        localStorage.clear();
        // et on redirige vers le formulaire de connexion
        history.push('/');
    },[]);

    return (
        <></>
    );
}

export default Logout;