import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import Input from "./Input";

const Client = ({ handleChange, form }) => {
  return (
    <div className="client">
      <div className="titleClient">
        <p>Client</p>
        <p className="choix">Les coordonnées du client.</p>
      </div>
      <div className="center">
        <div className="bloc mr">
          <Input
            label={"Nom"}
            handleChange={handleChange}
            type={"text"}
            placeholder={"Doe"}
            name={"nomContact"}
            form={form}
            messageText={"Nom du client requis"}
          />
          <Input
            label={"Email"}
            handleChange={handleChange}
            type={"email"}
            placeholder={"contact@client.fr"}
            name={"emailContact"}
            form={form}
            ref={form.register({
              required: true,
              pattern: /[a-z0-9._]+@[a-z0-9-]+\.[a-z]{2,3}/i,
            })}
            messageText={"Email du client requis."}
          />
        </div>

        <div className="bloc">
          <Input
            label={"Prénom"}
            handleChange={handleChange}
            type={"text"}
            placeholder={"John"}
            name={"prenomContact"}
            form={form}
            messageText={"Prénom du client requis."}
          />
          <Input
            label={"Téléphone"}
            handleChange={handleChange}
            type={"tel"}
            placeholder={"Sous la forme 0987654221 uniquement. Pas d'espace, pas de point ni de tiret."}
            name={"telephoneContact"}
            form={form}
            ref={form.register({
              required: true,
              pattern: /^0[1-9][0-9]{8}$/
            })}
            messageText={"Téléphone du client requis ou format incorrect."}
          />
        </div>

        <div>
          <label>
            Ville et département du bien concerné<span>*</span>
          </label>
          <div className="input">
            <input
              onChange={handleChange}
              className="fullsize"
              type="text"
              placeholder="Tours"
              name="villeContact"
              ref={form.register({ required: true })}
            />
            <ErrorMessage
              errors={form.errors}
              name="villeContact"
              message="Ville du bien requis."
              render={({ message }) => <span>{message}</span>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
